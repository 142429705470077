<!--
  @author: aSmecta
  @date: 2022/3/31
  @file: Footer
-->
<script setup>

</script>

<template>
  <footer class="container_footer">
    <span>&copy; 2020-2022, UGMark. All Rights Reserved </span>
    <a href="https://beian.miit.gov.cn/"><span>沪ICP备20022111号</span></a>
  </footer>
</template>

<style lang="less" scoped>
.container_footer {
  position: absolute;
  margin: 0 auto;
  bottom: 5px;
  font-size: 12px;
  color: rgb(192, 193, 196);
  a{
    color: rgb(192, 193, 196);
    text-decoration: none;
  }
}
</style>
